import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div id="services-container" className="cards">
      <h1>Conheça nossos serviços</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src="images/vets.jpg"
              text="Médicos veterinários qualificados e experientes"
              label="Clínica"
              // path="/clinica"
            />
            <CardItem
              src="images/hm5.png"
              text="Exames laboratoriais com equipamentos de ponta"
              label="Análises Clínicas"
              // path="/servicos"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src="images/spitz.jpeg"
              text="Banho & Tosa com carinho e qualidade"
              label="Estética animal"
              // path="/petshop"
            />
            <CardItem
              src="images/hills.png"
              text="Rações Super-Premium e produtos Pet selecionados"
              label="Pet Shop"
              // path="/produtos"
            />
            <CardItem
              src="images/emergencia.png"
              text="Atendimento emergencial 24h com que você pode contar"
              label="24h Emergência"
              href="https://wa.me/5518996405944"
              target="_blank"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
