/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from './../assets/logo-pequenos-animais.png';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className="navbar">
        <div id="navbar-container" className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img src={logo} alt="logo" style={{ maxHeight: 80 }} />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className="nav-item">
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                Início
              </Link>
            </li>
            <li className="nav-item">
              <a
                // to="/servicos"
                href="#services-container"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Serviços
              </a>
            </li>
            <li className="nav-item">
              <Link to="/sobre" className="nav-links" onClick={closeMobileMenu}>
                Sobre nós
              </Link>
            </li>

            <li>
              <a
                href="https://wa.me/5518996065944"
                className="nav-links-mobile"
                target="_blank"
                onClick={closeMobileMenu}
              >
                WhatsApp
              </a>
            </li>
            <div className="social-icons-navbar">
              <a
                className="social-icon-link facebook"
                href="https://facebook.com/pequenosanimais"
                target="_blank"
                aria-label="Facebook"
              >
                <i className="fab fa-facebook-f" />
              </a>
              <a
                className="social-icon-link instagram"
                href="https://instagram.com/pequenosanimais"
                target="_blank"
                aria-label="Instagram"
              >
                <i className="fab fa-instagram" />
              </a>
              <a
                className="social-icon-link whatsapp"
                href="https://wa.me/5518996065944"
                target="_blank"
                aria-label="WhatsApp"
              >
                <i className="fab fa-whatsapp" />
              </a>
              <a
                className="social-icon-link google-maps"
                href="https://goo.gl/maps/HL7xcvxMVfJt8QQKA"
                target="_blank"
                aria-label="Maps"
              >
                <i className="fas fa-map-marker-alt"></i>
              </a>
            </div>
          </ul>
          {button && (
            <Button
              href="https://wa.me/5518996065944"
              target="_blank"
              buttonStyle="btn--primary"
            >
              WhatsApp
            </Button>
          )}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
