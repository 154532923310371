/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import './Footer.css';
// import { Button } from './Button';
import { Link } from 'react-router-dom';

const currentYear = new Date().getFullYear();

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Estrutura</h2>
            <p>Clínica</p>
            <p>Cirurgia</p>
            <p>Internamento</p>
            <p>Exames Laboratoriais</p>
            <p>Pet Shop</p>
            <p>Banho e Tosa</p>
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Redes Sociais</h2>
            <a href="https://facebook.com/pequenosanimais" target="_blank">
              Facebook
            </a>
            <a href="https://instagram.com/pequenosanimais" target="_blank">
              Instagram
            </a>
            <a href="https://wa.me/5518996065944" target="_blank">
              WhatsApp
            </a>
          </div>
        </div>
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link to="/" className="social-logo">
              PEQUENOS ANIMAIS
            </Link>
          </div>
          <small className="website-rights">
            todos os direitos reservados © {currentYear}
          </small>
          <div className="social-icons">
            <a
              className="social-icon-link facebook"
              href="https://facebook.com/pequenosanimais"
              target="_blank"
              aria-label="Facebook"
            >
              <i className="fab fa-facebook-f" />
            </a>
            <a
              className="social-icon-link instagram"
              href="https://instagram.com/pequenosanimais"
              target="_blank"
              aria-label="Instagram"
            >
              <i className="fab fa-instagram" />
            </a>
            <a
              className="social-icon-link whatsapp"
              href="https://wa.me/5518996065944"
              target="_blank"
              aria-label="WhatsApp"
            >
              <i className="fab fa-whatsapp" />
            </a>
            <a
              className="social-icon-link google-maps"
              href="https://goo.gl/maps/HL7xcvxMVfJt8QQKA"
              target="_blank"
              aria-label="Maps"
            >
              <i className="fas fa-map-marker-alt"></i>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
