import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Sobre from './pages/Sobre';
import ScrollToTop from './components/ScrollToTop';
import ScrollTopArrow from './components/ScrollTopArrow';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
        </Switch>
        <Switch>
          <Route path="/sobre" component={Sobre} />
        </Switch>
        <Footer />
        <ScrollTopArrow />
      </Router>
    </>
  );
}

export default App;
