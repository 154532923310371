import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';

const yearsActive = new Date().getFullYear() - 1983;

function HeroSection() {
  return (
    <div className="hero-container">
      {/* <video src="/videos/video-1.mp4" autoPlay loop muted /> */}
      {/* <img src="/images/foto-frente.jpg" alt="foto-frente" /> */}
      <h1>
        Há <strong>{yearsActive} anos</strong>
      </h1>
      <h2>servindo você</h2>
      <div className="hero-btns">
        <Button
          className="btns"
          buttonStyle="btn--inverted"
          buttonSize="btn--large"
          href="#services-container"
        >
          CONHEÇA NOSSOS SERVIÇOS
        </Button>
        <Button
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
          href="https://youtu.be/Iz_Gn7KnbPs"
        >
          ASSISTA <i className="far fa-play-circle" />
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
