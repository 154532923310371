import React from 'react';
import './Pages.css';

export default function Sobre() {
  return (
    <div className="page-container">
      <div className="sobre">
        <div className="page-content__header">
          <h1 className="page-content__title">Nossa História</h1>
        </div>

        <h3 className="page-content__subtitle">Primeiros passos</h3>
        <div className="page-content__text">
          <p>
            Em dezembro de 1982, o Dr. Marco Peixoto se graduava em Medicina
            Veterinária pela Universidade Estadual de Londrina.
          </p>
          <p>
            Já tendo feito estágio, durante as férias dos anos de faculdade, com
            o Dr. Edson Luiz de Campos em Araçatuba, retorna para aquela cidade
            e fica por mais 4 meses estagiando com o profissional. Ali, pôde
            aprender com um profissional de respeito e de reconhecida
            competência, os primeiros passos da profissão, como clínico e
            cirurgião formado. Tendo recebido a proposta de se tornar sócio do
            Dr. Edson em sua clínica, apesar de muito tentadora a proposta para
            o recém formado Dr. Marco, este fez uma proposta que até hoje vigora
            em profunda estima de ambos: "em vez de sócios, vamos continuar
            amigos".
          </p>
          <p>
            O Dr. Marco nutre profundo respeito e admiração até hoje pelo Dr.
            Edson por toda a confiança que depositou nele, e também pela imensa
            contribuição que lhe deu no início de sua carreira; também seu
            caráter e profissionalismo são dignos de nota.
          </p>

          <h3 className="page-content__subtitle">Presidente Prudente</h3>
          <p>
            Após esse período, surge a questão: onde realizar o sonho de exercer
            a clínica médica e cirúrgica de pequenos animais?
          </p>
          <p>
            Depois de fazer uma pesquisa em vários bairros da capital do estado
            de São Paulo e cidades da região, e ter praticamente decidido ficar
            na capital, depois de uma noite refletindo sobre como seria a vida
            ali, ele visualizou que sua vida e clientela precisaria ser toda
            estabelecida naquela cidade. Assim, não se viu encaixado à uma
            possível vida na megalópole, decidiu retornar à sua terra natal.
          </p>
          <p>
            Após pesquisar nas cidades da região de Presidente Prudente,
            imaginando que nesta cidade o mercado estivesse saturado em seu ramo
            (havia 5 clínicas veterinárias), ainda assim{' '}
            <ins>
              decidiu que Presidente Prudente seria o melhor lugar para exercer
              a profissão.
            </ins>
          </p>
        </div>
      </div>
    </div>
  );
}
